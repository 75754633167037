<template>
    <router-view></router-view>
</template>

<script>

export default {
    name: 'Dashboard',
    data: () => ({
    }),
    computed: {
    },
    props: [],
    watch: {
    },
    methods: {
    },
    created() {
    },
    beforeDestroy(){
    },
    mounted() {
    },
    components: {
    },
}
</script>